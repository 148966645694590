export const LOCAL_STORAGE_KEYS = {
  DISMISSED_UPDATES: 'dismissedUpdates',
  WIDGET_BUILDER: 'widgetBubble_',
  DRAFT_MESSAGES: 'draftMessages',
  COLOR_SCHEME: 'color_scheme',
  DISMISSED_LABEL_SUGGESTIONS: 'labelSuggestionsDismissed',
  MESSAGE_REPLY_TO: 'messageReplyTo',
  COLOR_BACKGROUND_CHAT: 'color_background_chat',
  CBC_DEFAULT: 'chat_default',
  CBC_AVATAR: 'chat_avatar',
};
